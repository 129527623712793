import _interceptor from "../interceptor";
import _mime from "../mime";
import _registry from "../mime/registry";
import _attempt from "../util/attempt";
var exports = {};
var interceptor, mime, registry, noopConverter, missingConverter, attempt;
interceptor = _interceptor;
mime = _mime;
registry = _registry;
attempt = _attempt;
noopConverter = {
  read: function (obj) {
    return obj;
  },
  write: function (obj) {
    return obj;
  }
};
missingConverter = {
  read: function () {
    throw "No read method found on converter";
  },
  write: function () {
    throw "No write method found on converter";
  }
};
/**
 * MIME type support for request and response entities.  Entities are
 * (de)serialized using the converter for the MIME type.
 *
 * Request entities are converted using the desired converter and the
 * 'Accept' request header prefers this MIME.
 *
 * Response entities are converted based on the Content-Type response header.
 *
 * @param {Client} [client] client to wrap
 * @param {string} [config.mime='text/plain'] MIME type to encode the request
 *   entity
 * @param {string} [config.accept] Accept header for the request
 * @param {Client} [config.client=<request.originator>] client passed to the
 *   converter, defaults to the client originating the request
 * @param {Registry} [config.registry] MIME registry, defaults to the root
 *   registry
 * @param {boolean} [config.permissive] Allow an unkown request MIME type
 *
 * @returns {Client}
 */

exports = interceptor({
  init: function (config) {
    config.registry = config.registry || registry;
    return config;
  },
  request: function (request, config) {
    var type, headers;
    headers = request.headers || (request.headers = {});
    type = mime.parse(headers["Content-Type"] || config.mime || "text/plain");
    headers.Accept = headers.Accept || config.accept || type.raw + ", application/json;q=0.8, text/plain;q=0.5, */*;q=0.2";

    if (!("entity" in request)) {
      return request;
    }

    headers["Content-Type"] = type.raw;
    return config.registry.lookup(type)["catch"](function () {
      // failed to resolve converter
      if (config.permissive) {
        return noopConverter;
      }

      throw "mime-unknown";
    }).then(function (converter) {
      var client = config.client || request.originator,
          write = converter.write || missingConverter.write;
      return attempt(write.bind(void 0, request.entity, {
        client: client,
        request: request,
        mime: type,
        registry: config.registry
      }))["catch"](function () {
        throw "mime-serialization";
      }).then(function (entity) {
        request.entity = entity;
        return request;
      });
    });
  },
  response: function (response, config) {
    if (!(response.headers && response.headers["Content-Type"] && response.entity)) {
      return response;
    }

    var type = mime.parse(response.headers["Content-Type"]);
    return config.registry.lookup(type)["catch"](function () {
      return noopConverter;
    }).then(function (converter) {
      var client = config.client || response.request && response.request.originator,
          read = converter.read || missingConverter.read;
      return attempt(read.bind(void 0, response.entity, {
        client: client,
        response: response,
        mime: type,
        registry: config.registry
      }))["catch"](function (e) {
        response.error = "mime-deserialization";
        response.cause = e;
        throw response;
      }).then(function (entity) {
        response.entity = entity;
        return response;
      });
    });
  }
});
export default exports;